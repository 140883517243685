<template>
  <div class="thanksCoach">
    <SvgIcon icon="thumb-up--red" />
    <div class="thanksCoach__header">
      Thanks!
    </div>
    <div class="thanksCoach__text" v-text="infoMessage"></div>
  </div>
</template>

<script>
const SvgIcon = () => import('@/components/SvgIcon');

export default {
  name: 'ThanksCoach',
  components: {
    SvgIcon,
  },
  computed: {
    infoMessage() {
      const { CLAIMANT_NAME } = this.$route.params;
      return `We have updated ${CLAIMANT_NAME} FNOL.`;
    },
  },
  created() {
    const { CLAIMANT_NAME } = this.$route.params;
    if (!CLAIMANT_NAME) this.$router.push({ name: 'notFound' });
  },
};
</script>

<style lang="scss" scoped>
.thanksCoach {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding-bottom: 76px;
}
.thanksCoach__header {
  margin-top: 22px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.06px;
  color: #000000;
}
.thanksCoach__text {
  margin-top: 10px;
  font-style: normal;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  letter-spacing: -0.06px;
  color: #000000;
  max-width: 464px;
}
</style>
